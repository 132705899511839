@import url('https://fonts.googleapis.com/css?family=Yesteryear');

body {
    font-size: 30pt;
    font-family: 'Yesteryear', cursive;
}

@media screen and (min-width: 768px) and (orientation:landscape) {

}

@media screen and (min-height: 768px) and (orientation:portrait) {

}

.text-center {
    text-align: center;
}

h1,
h2,
h3,
h4 {
    margin: 0;
}

h1 {
    text-align: center;
    line-height: 0.9em;
    font-size: 15vw;
}