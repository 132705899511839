@import 'style/_variables.scss';
@import 'style/_breakpoints.scss';
@import 'style/_typography.scss';

* {
    box-sizing: border-box;
}

body {
    background: #000;
    color: #eee;
    overflow-x: hidden;
}

.App {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.centered {
    align-self: center;
}

.bg-text {
    position: relative;
    color: transparent;
    background-size: cover;
    background-position: center;
    background-clip: text;
    -webkit-background-clip: text;
}

.faded-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity : 0.2;
    background-size: cover;
    background-position: center;
}

.grid-warning{
    color: red;
    border: 3px dashed red;
    margin: 1em;
    padding: 1em;
}

@supports(display: grid) {
  /* Hide warnings */
  .grid-warning{
    display: none;
  }
}
