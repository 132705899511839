@import url(https://fonts.googleapis.com/css?family=Yesteryear);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body {
  font-size: 30pt;
  font-family: 'Yesteryear', cursive; }

.text-center {
  text-align: center; }

h1,
h2,
h3,
h4 {
  margin: 0; }

h1 {
  text-align: center;
  line-height: 0.9em;
  font-size: 15vw; }

* {
  box-sizing: border-box; }

body {
  background: #000;
  color: #eee;
  overflow-x: hidden; }

.App {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden; }

.centered {
  align-self: center; }

.bg-text {
  position: relative;
  color: transparent;
  background-size: cover;
  background-position: center;
  background-clip: text;
  -webkit-background-clip: text; }

.faded-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-size: cover;
  background-position: center; }

.grid-warning {
  color: red;
  border: 3px dashed red;
  margin: 1em;
  padding: 1em; }

@supports (display: grid) {
  /* Hide warnings */
  .grid-warning {
    display: none; } }

